// extracted by mini-css-extract-plugin
export var errorMessage = "WebinarRegister-module--error-message--04a20";
export var formWrapper = "WebinarRegister-module--form-wrapper--8f7f3";
export var inputIcon = "WebinarRegister-module--input-icon--710ba";
export var inputWrapper = "WebinarRegister-module--input-wrapper--71436";
export var labelCheckboxGroup = "WebinarRegister-module--label-checkbox-group--21396";
export var labelInputGroup = "WebinarRegister-module--label-input-group--d7217";
export var radioLabel = "WebinarRegister-module--radio-label--67558";
export var sendButton = "WebinarRegister-module--send-button--03ce1";
export var sendMessage = "WebinarRegister-module--send-message--9741d";
export var subtitle = "WebinarRegister-module--subtitle--d22b1";
export var title = "WebinarRegister-module--title--f95ca";
export var webinarRegisterBlock = "WebinarRegister-module--webinar-register-block--6b1e1";