// extracted by mini-css-extract-plugin
export var category = "post-blog-module--category--aacbb";
export var excerpt = "post-blog-module--excerpt--f694b";
export var postAuthor = "post-blog-module--post-author--7efe9";
export var postAuthorImg = "post-blog-module--post-author-img--44f3c";
export var postAuthorName = "post-blog-module--post-author-name--a8734";
export var postDate = "post-blog-module--post-date--0c74b";
export var postImg = "post-blog-module--post-img--bddbc";
export var postItem = "post-blog-module--post-item--b6296";
export var postTitle = "post-blog-module--post-title--7d96f";
export var small = "post-blog-module--small--f1b5e";