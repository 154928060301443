import { graphql } from "gatsby"
import React from "react"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css"
import "../assets/css/@wordpress/block-library/build-style/theme.css"
import RelatedPostsBlock from './../components/RelatedPostsBlock/RelatedPostsBlock'
import Faq from './../components/faq/faq'
import Layout from "./../components/layout"
import Seo from "./../components/seo"
import WebinarAbout from './../components/webinars/WebinarAbout/WebinarAbout'
import WebinarAfterRecording from "./../components/webinars/WebinarAfterRecording/WebinarAfterRecording"
import WebinarAgenda from './../components/webinars/WebinarAgenda/WebinarAgenda'
import WebinarHero from "./../components/webinars/WebinarHero/WebinarHero"
import WebinarRegister from "./../components/webinars/WebinarRegister/WebinarRegister"
import WebinarSpeaker from "./../components/webinars/WebinarSpeaker/WebinarSpeaker"
import WebinarWhyJoin from './../components/webinars/WebinarWhyJoin/WebinarWhyJoin'
import WebinarsBlock from "./../components/webinars/WebinarsBlock/WebinarsBlock"
import schemaBuilder from "./../utils/schemaBuilder"

const WebinarTemplate = ({ data: { post, site }, location }) => {

  const schema = schemaBuilder({ webinar: post }, 'webinar', site);

  return (
    <Layout  pageTitle={`Webinar - ${post.title}`}>
      <Seo
        title={post.title}
        description={post.excerpt}
        pageSeo={post}
        location={location}
        schema={schema}
      />
      <section>
        <WebinarHero block={post.acf_webinar?.hero} />
        <WebinarAbout block={post.acf_webinar?.about} />
        <WebinarAgenda block={post.acf_webinar?.agenda} />
        <WebinarSpeaker block={post.acf_webinar?.speaker} />
        <WebinarWhyJoin block={post.acf_webinar?.whyJoin} />
        <div id="register" style={{ scrollMarginTop: '95px' }}>
          <WebinarRegister block={post.acf_webinar?.register} webinar={post.title} />
          <WebinarAfterRecording block={post.acf_webinar?.afterWebinar} webinar={post.title} />
        </div>
        {post.acf_webinar?.relatedPosts?.length > 0 &&
          <RelatedPostsBlock block={{ posts: post.acf_webinar?.relatedPosts, title: <>Related Blogs</> }} />}

        {!!post.acf_webinar?.faqBlock?.items?.length &&
          <Faq block={{ items: post.acf_webinar?.faqBlock?.items, title: post.acf_webinar?.faqBlock?.title }} />
        }
        <WebinarsBlock block={post.acf_webinar?.otherWebinars} />
      </section>
    </Layout >
  )
}

export default WebinarTemplate

export const pageQuery = graphql`
  query WebinarById(
    $id: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    post: wpWebinar(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      acf_webinar {
        general {
          start
          end
          status
          youtubeUrl
          zoomUrl
        }
        hero {
          backgroundColor
          title
          subtitle
          useCustomLink
          buttonText
          buttonLink {
            url
            title
            target
          }
          webinarDate
          time
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageMobile {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        about {
          show
          backgroundColor
          title
          leftTitle
          leftContent
          rightTitle
          rightContent
          useCustomLink
          buttonText
          buttonLink {
            url
            title
            target
          }
        }
        agenda {
          show
          backgroundColor
          title
          webinarDate
          time
          duration
          price
          items {
            title
            content
            time
          }
        }
        speaker {
          show
          backgroundColor
          title
          content
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageMobile {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          useCustomLink
          buttonText
          buttonLink {
            url
            title
            target
          }
        }
        whyJoin {
          show
          backgroundColor
          title
          content
        }
        relatedPosts {
          post {
            ... on WpPost {
              excerpt
              seo {
                metaDesc
              }
              acf_post {
                heading {
                  cardBackground
                }
              }
              uri
              date(formatString: "MMM DD, YYYY")
              title
              excerpt
              categories {
                nodes {
                  slug
                  name
                }
              }
              readingTime {
                text
              }
              tags {
                nodes {
                  name
                }
              }
              author {
                node {
                  avatar {
                    size
                    url
                  }
                  users {
                    avatar {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  name
                  slug
                  uri
                }
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        register {
          show
          backgroundColor
          webhookUrl
          title
          subtitle
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        afterWebinar {
          show
          backgroundColor
          title
          subtitle
          youtubeVideoId
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        faqBlock {
          title
          items {
            question
            answer
          }
        }
        otherWebinars {
          show
          backgroundColor
          title
          webinars {
            webinar {
              ... on WpWebinar {
                id
                uri
                title
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
