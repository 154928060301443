// extracted by mini-css-extract-plugin
export var bg = "WebinarHero-module--bg--553b9";
export var content = "WebinarHero-module--content--f03d9";
export var dateTime = "WebinarHero-module--date-time--33bc2";
export var imgWrapper = "WebinarHero-module--img-wrapper--d5212";
export var marquee = "WebinarHero-module--marquee--00e80";
export var movingText = "WebinarHero-module--moving-text--8e910";
export var row = "WebinarHero-module--row--d9c20";
export var runningText = "WebinarHero-module--running-text--f6bde";
export var title = "WebinarHero-module--title--e0f59";
export var topBlockButton = "WebinarHero-module--top-block-button--988f9";
export var webinarHeroBlock = "WebinarHero-module--webinar-hero-block--cdd8d";