import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./SingleWebinar.module.css";

const SingleWebinar = ({ webinar }) => {
    const image = {
        data: webinar.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: webinar.featuredImage?.node?.altText || ``,
    };
    return <>
        <Link to={webinar.uri} className={s.webinarItem}>
            {(image.data) &&
                <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={image.data}
                    alt={image.alt}
                    className={`mw-100 ${s.imageWrapper}`} />
            }
            {!!webinar.title && <h2>{parse(webinar.title)}</h2>}
        </Link>
    </>
}

export default SingleWebinar;