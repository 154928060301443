import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import * as s from "./WebinarAgenda.module.css";

const WebinarAgenda = ({ block }) => {
  const bp = useBreakpoint();

  const webinarAgendaRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    webinarAgendaRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarAgendaBlock-bg-color"] = block.backgroundColor;
  }

  return (
    <>
      {!!block.show &&
        <section className={`${s.webinarAgendaBlock}`} style={styles} ref={webinarAgendaRef}>
          <div className="container">
            {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
            {/* {enterCount > 0 && */}
              <>
                <div className={`row justify-content-center`}>
                  {!!block.webinarDate && <div className={`${s.infoItem}`}>
                    <div className={`${s.icon}`}>
                      {!!bp.md ?
                        <StaticImage
                          src="./images/date.png"
                          alt="date"
                          height={54}
                        />
                        :
                        <StaticImage
                          src="./images/date.png"
                          alt="date"
                          height={32}
                        />
                      }
                    </div>
                    <b>Date</b>
                    <span>{parse(block.webinarDate)}</span>
                  </div>}
                  {!!block.webinarDate && <div className={`${s.infoItem}`}>
                    <div className={`${s.icon}`}>
                      {!!bp.md ?
                        <StaticImage
                          src="./images/time.png"
                          alt="time"
                          height={54}
                        />
                        :
                        <StaticImage
                          src="./images/time.png"
                          alt="time"
                          height={32}
                        />
                      }
                    </div>

                    <b>Time</b>
                    <span>{parse(block.time)}</span>
                  </div>}
                  {!!block.webinarDate && <div className={`${s.infoItem}`}>
                    <div className={`${s.icon}`}>
                      {!!bp.md ?
                        <StaticImage
                          src="./images/duration.png"
                          alt="duration"
                          height={54}
                        />
                        :
                        <StaticImage
                          src="./images/duration.png"
                          alt="duration"
                          height={32}
                        />
                      }
                    </div>
                    <b>Duration</b>
                    <span>{parse(block.duration)}</span>
                  </div>}
                  {!!block.webinarDate && <div className={`${s.infoItem}`}>
                    <div className={`${s.icon}`}>
                      {!!bp.md ?
                        <StaticImage
                          src="./images/price.png"
                          alt="price"
                          height={54}
                        />
                        :
                        <StaticImage
                          src="./images/price.png"
                          alt="price"
                          height={32}
                        />
                      }
                    </div>
                    <b>Price</b>
                    <span>{parse(block.price)}</span>
                  </div>}

                  {block.items?.length > 0 &&
                    <div className={`${s.agendaItems} col-xl-10 col-xxl-9`}>
                      {block.items.map((item, i) => {
                        return render_single_item(item, i);
                      })}
                    </div>
                  }
                </div>
              </>
            {/* } */}
          </div>
        </section>
      }
    </>
  );
};

const render_single_item = (item, i) => {
  return <div className={`${s.agendaItem} row align-items-center`} key={`agenda-item-${i}`}>
    <div className='col-md-6 col-xl-5 order-2 order-md-1'>
      {!!item.title && <h3>{parse(item.title)}</h3>}
      {!!item.content && <div className={`${s.content}`}>
        {parse(item.content)}
      </div>}
    </div>
    <div className='col-md-6 col-xl-5 offset-xl-2 order-1 order-md-2'>
      {!!item.time && <div className={`${s.time}`}>
        {parse(item.time)}
      </div>}
    </div>
  </div>
}

export default WebinarAgenda;
