import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./post-blog.module.css";

const PostBlog = ({ post, mode }) => {
  const image = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  };
  const category = post?.categories?.nodes[0];
  const author = post.author.node;

  const authorAvatarImage = {
    data: author.users.avatar?.localFile?.childImageSharp?.gatsbyImageData,
    alt: author.users.avatar?.altText || ``,
  };

  const postImgStyles = [];
  if (post?.acf_post?.heading?.cardBackground) {
    postImgStyles['--card-bg'] = post?.acf_post?.heading?.cardBackground;
  }
  return (
    <>
      <article
        className={`${s.postItem} ${('small' == mode) ? s.small : ''}`}
      >
        <div className={s.postTitle}>
          {image?.data && (
            <Link to={post.uri} className={s.postImg} style={postImgStyles}>
              <GatsbyImage
                loading="eager"
                placeholder="none"
                image={image.data}
                alt={image.alt}
                className="w-100 h-100"
              />
            </Link>
          )}
          <div className={`${'small' == mode ? "px-2" : "px-3"}`}>
            <div className={`d-flex align-items-center justify-content-between ${s.postAuthor}`}>
              <div className="d-flex align-items-center justify-content-start">
                {!!authorAvatarImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={authorAvatarImage.data}
                    alt={author.name}
                    className={s.postAuthorImg} />
                }
                {!authorAvatarImage.data &&
                  <img src={author.avatar.url} alt={author.name} className={`${s.postAuthorImg}`} />
                }
                <div className={`ms-2 ${'small' == mode ? "" : "ms-md-3"}`}>
                  <Link to={author.uri} className={`${s.postAuthorName}`}>
                    {author.name}
                  </Link>
                  <div className={`${s.postDate}`}>
                    {post.date} {!!post?.readingTime?.text && <span> &#8729; {post?.readingTime?.text}</span>}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {!!category && <div className={s.category}>{category?.name}</div>}
              </div>
            </div>
            <Link to={post.uri} className="text-decoration-none"><h3 >{parse(post.title)}</h3></Link>
            <div className={s.excerpt}>{parse(post.seo.metaDesc)}</div>
          </div>
        </div>
      </article>
    </>
  );
};

export default PostBlog;
