// extracted by mini-css-extract-plugin
export var errorMessage = "WebinarAfterRecording-module--error-message--564cd";
export var formWrapper = "WebinarAfterRecording-module--form-wrapper--c088d";
export var inputIcon = "WebinarAfterRecording-module--input-icon--db5be";
export var inputWrapper = "WebinarAfterRecording-module--input-wrapper--66bad";
export var labelCheckboxGroup = "WebinarAfterRecording-module--label-checkbox-group--7541b";
export var labelInputGroup = "WebinarAfterRecording-module--label-input-group--5cff6";
export var radioLabel = "WebinarAfterRecording-module--radio-label--d3745";
export var sendButton = "WebinarAfterRecording-module--send-button--c7009";
export var sendMessage = "WebinarAfterRecording-module--send-message--07952";
export var subtitle = "WebinarAfterRecording-module--subtitle--68e06";
export var title = "WebinarAfterRecording-module--title--7bff2";
export var webinarRegisterBlock = "WebinarAfterRecording-module--webinar-register-block--d82bd";
export var youtubeVideoWrapper = "WebinarAfterRecording-module--youtube-video-wrapper--4d691";