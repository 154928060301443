import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import Slider from "react-slick";
import PostBlog from './../post-blog/post-blog';
import * as s from "./RelatedPostsBlock.module.css";

const RelatedPostsBlock = ({ block }) => {

  const bp = useBreakpoint();

  const blockRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    blockRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    // centerMode: true,
    centerPadding: '8px',
    responsive: [
      {
        breakpoint: 1199.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className={`${s.seoSeoResources}`} ref={blockRef}>
        <div className={s.wrapper}>
          {enterCount > 0 &&
            <>
              <div className="container">
                <div className="row">
                  <h2>{block.title}</h2>
                </div>
              </div>
              {bp.xl ?
                <div className="container d-none d-xl-block">
                  <div className="row justify-content-center">
                    {!!block.posts &&
                      block.posts.map((item, i) => {
                        return <div className="col-sm-12 col-md-6 col-lg-4" key={`related-post-${i}`}>
                          <PostBlog post={item.post} mode="small" />
                        </div>;
                      })
                    }
                  </div>
                </div>
                :
                <div className="d-xl-none">
                  {!!block.posts &&
                    <Slider {...sliderSettings}>
                      {block.posts.map((item, i) => {
                        return <div key={`related-slider-post-${i}`} className="px-2"><PostBlog post={item.post} mode="small" /></div>;
                      })}
                    </Slider>
                  }
                </div>
              }
              <div className="container">
                <div className="row">
                  <div className='col'>
                    <div className='d-flex justify-content-center'>
                      <Link className={s.viewAllButton} to="/blog">View All</Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </section>
    </>
  );
};

export default RelatedPostsBlock;
