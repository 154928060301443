import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import makeZapierRequest from '../../../utils/zapierRequests';
import zapiers from "../../../utils/zapiers";
import * as s from "./WebinarRegister.module.css";

const WebinarRegister = ({ block, webinar }) => {
  const bp = useBreakpoint();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyWebsite, setCompanyWebsite] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [learnInWebinar, setLearnInWebinar] = React.useState("");
  const [acceptChecked, setAcceptChecked] = React.useState(false);
  const [subscribeChecked, setSubscribeChecked] = React.useState(true);
  const [errors, setErrors] = React.useState([]);
  const [messageSent, setMessageSent] = React.useState(false);


  const handleAcceptCheckboxChange = (e) => {
    setAcceptChecked(e.target.checked);
  }

  const handleSubscribeCheckboxChange = (e) => {
    setSubscribeChecked(e.target.checked);
  }

  const submitForm = () => {
    if (isValidForm() && !messageSent) {
      setMessageSent(true);
      let payload = {
        event: 'formSubmit',
        type: zapiers.webinarRegistration.type,
        firstName: firstName,
        lastName: lastName,
        email: email,
        company: company,
        companyWebsite: companyWebsite,
        position: position,
        level: level,
        learnInWebinar: learnInWebinar,
        webinar: webinar,
        subscribeChecked: subscribeChecked,
      };

      makeZapierRequest(payload, block.webhookUrl);
    }
  }

  const isValidForm = () => {
    let isValid = true;
    let newErrors = [];

    if (!firstName?.length) {
      isValid = false;
      newErrors.push('Please enter a First Name');
    }
    if (!lastName?.length) {
      isValid = false;
      newErrors.push('Please enter a Last Name');
    }

    if (!isEmailValid(email)) {
      isValid = false;
      newErrors.push('Please enter a valid Email');
    }

    setErrors(newErrors);
    return isValid;
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  const webinarRegisterRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    webinarRegisterRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };
  const imageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageMobile?.altText || ``,
  };

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarRegisterBlock-bg-color"] = block.backgroundColor;
  }

  return (
    <>
      {!!block.show &&
        <section className={`${s.webinarRegisterBlock}`} style={styles} ref={webinarRegisterRef}>
          <div className="container">
            <div className={`row align-items-center`}>
              <div className="col-xl-12">
                {!!block.title && <h2 className={`${s.title} text-center`}>{parse(block.title)}</h2>}
                {!!block.subtitle && <div className={`${s.subtitle} text-center`}>{parse(block.subtitle)}</div>}
              </div>
              <div className="col-12"></div>
              {/* {enterCount > 0 && */}
              <>
                {!!bp.xl &&
                  <div className="col-12 mb-3">
                    {(image.data) &&
                      <GatsbyImage
                        loading="eager"
                        placeholder="dominantColor"
                        image={image.data}
                        alt={image.alt}
                        className={`mw-100`} />
                    }
                  </div>
                }
                <div className="col-12 col-lg-8 offset-lg-2">
                  <div className={`${s.formWrapper} row mx-0`}>
                    <div className="col-sm-6 ps-sm-0 pe-sm-2">
                      <div className={`${s.labelInputGroup}`}>
                        <label htmlFor="name">First Name <span className="text-danger">*</span></label>
                        <div className={s.inputWrapper}>
                          <input
                            type="text"
                            name="name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            placeholder="First Name" />
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 ps-sm-2 pe-sm-0">
                      <div className={`${s.labelInputGroup}`}>
                        <label htmlFor="name">Last Name <span className="text-danger">*</span></label>
                        <div className={s.inputWrapper}>
                          <input
                            type="text"
                            name="name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            placeholder="Last Name" />
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 ps-sm-0 pe-sm-2">
                      <div className={`${s.labelInputGroup} w-100`}>
                        <label htmlFor="email">Email <span className="text-danger">*</span></label>
                        <div className={s.inputWrapper}>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Email address" />
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 ps-sm-2 pe-sm-0">
                      <div className={`${s.labelInputGroup}`}>
                        <label htmlFor="position">Current job title or position?</label>
                        <div className={s.inputWrapper}>
                          <input
                            type="text"
                            name="name"
                            value={position}
                            onChange={e => setPosition(e.target.value)}
                            placeholder="Position" />
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 ps-sm-0 pe-sm-2">
                      <div className={`${s.labelInputGroup}`}>
                        <label htmlFor="company">Company Name </label>
                        <div className={s.inputWrapper}>
                          <input
                            type="text"
                            name="company"
                            value={company}
                            onChange={e => setCompany(e.target.value)}
                            placeholder="Company Name" />
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 ps-sm-2 pe-sm-0">
                      <div className={`${s.labelInputGroup} w-100`}>
                        <label htmlFor="copm-website">Company Website</label>
                        <div className={s.inputWrapper}>
                          <input
                            type="text"
                            name="companyWebsite"
                            value={companyWebsite}
                            onChange={e => setCompanyWebsite(e.target.value)}
                            placeholder="Company Website" />
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 px-sm-0">
                      <div className={`${s.labelInputGroup}`}>
                        <label className='ps-0'>How would you rate your knowledge in the webinar topic?</label>
                        <div className={`${s.inputWrapper} d-flex flex-wrap`} style={{ gap: '12px' }}>
                          <label className={s.radioLabel}><input type='radio' value={'Novice'} checked={level === "Novice"} onChange={() => { setLevel('Novice') }} /> Novice</label>
                          <label className={s.radioLabel}><input type='radio' value={'Intermediate'} checked={level === "Intermediate"} onChange={() => { setLevel('Intermediate') }} /> Intermediate</label>
                          <label className={s.radioLabel}><input type='radio' value={'Expert'} checked={level === "Expert"} onChange={() => { setLevel('Expert') }} /> Expert</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 px-sm-0">
                      <div className={`${s.labelInputGroup}`}>
                        <label className='ps-0'>What specific aspects or topics would you like to learn more about in this webinar?</label>
                        <div className={s.inputWrapper}>
                          <textarea
                            rows="3"
                            type="text"
                            name="learnInWebinar"
                            value={learnInWebinar}
                            onChange={e => setLearnInWebinar(e.target.value)}
                            placeholder="" ></textarea>
                          <span className={s.inputIcon}></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 ps-sm-0">
                      <label className={`${s.labelCheckboxGroup} w-100`}>
                        <input type="checkbox" onChange={handleAcceptCheckboxChange} />
                        I have read and accept the &nbsp;<a href="/privacy-policy/" target="_blank"> Privacy Policy</a>.
                      </label>
                    </div>
                    <div className="col-12 ps-sm-0 mt-2">
                      <label className={`${s.labelCheckboxGroup} w-100`}>
                        <input type="checkbox" onChange={handleSubscribeCheckboxChange} checked={subscribeChecked} />
                        Subscribe to [A] Growth newsletter
                      </label>
                    </div>
                    <div className="col-12 ps-sm-0">
                      {!messageSent && <input type="button" value="Submit" disabled={!acceptChecked} onClick={submitForm} className={`${s.sendButton}`} />}
                    </div>
                    <div className="col-12 ps-sm-0">
                      {messageSent && <div className={`${s.sendMessage}`}>You have successfully Registered</div>}
                      {errors?.length > 0 &&
                        <div className={`mt-3 d-flex flex-column`}
                          style={{ 'gap': '4px', 'maxWidth': 'max-content' }}>
                          {errors?.map((err, erri) => {
                            return <div className={`${s.errorMessage}`} key={`error-${erri}`}>{err}</div>
                          })}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
              {/* } */}
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default WebinarRegister;
