import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import * as s from "./faq.module.css";
import FaqQuestion from "./faqquestion";

const Faq = ({ block }) => {

  const faqRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    faqRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const defaultTitle = "Frequently Asked Questions";
  return (
    <section className={s.accordionBlock} ref={faqRef}>
      <div className="container ">
        <div className="d-flex justify-content-center align-items-center">
          <h2 className={`blog-title`}>
            {block.title !== undefined ? block.title : defaultTitle}
          </h2>
        </div>
        <div className="row">
          <div className={`${s.accordion} col-12`}>
            {(enterCount > 0 && !!block.items) &&
              block.items.map((q, i) => {
                return (
                  <FaqQuestion key={`faqquestion-${i}`} number={i + 1} question={q.question}>
                    {q.answer}
                  </FaqQuestion>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
