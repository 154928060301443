import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import Slider from "react-slick";
import SingleWebinar from "../SingleWebinar/SingleWebinar";
import * as s from "./WebinarsBlock.module.css";

const WebinarsBlock = ({ block }) => {
  const bp = useBreakpoint();

  const webinarsBlockRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    webinarsBlockRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    // centerMode: true,
    centerPadding: '8px',
  };

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarsBlock-bg-color"] = block.backgroundColor;
  }

  return (
    <>
      {(!!block.show && block.webinars?.length > 0) &&
        <section className={`${s.webinarsBlock}`} style={styles} ref={webinarsBlockRef}>
          <div className="container">
            <div className={`row align-items-center`}>
              <div className="col-12">
                {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
                {!!block.subtitle && <div className={`${s.subtitle}`}>{parse(block.subtitle)}</div>}
              </div>
              <>
                {!!bp.md ?
                  <div className="container">
                    <div className="row justify-content-center">
                      {block.webinars.map((item, i) => {
                        return <div className="col-sm-12 col-md-6 col-xl-4 py-3" key={`webinar-item-${i}`}>
                          <SingleWebinar webinar={item.webinar} />
                        </div>
                      })}
                    </div>
                  </div>
                  :
                  <div className="container-fluid px-0">
                    <Slider {...sliderSettings}>
                      {block.webinars.map((item, i) => {
                        return <div key={`webinars-slider-item-${i}`} className="px-3"><SingleWebinar webinar={item.webinar} /></div>
                      })}
                    </Slider>
                  </div>
                }
              </>
              {!!block?.link?.url && <div className='col-12 d-flex justify-content-center mt-3'>
                <Link className={s.ctaBtn} to={block?.link?.url} target={block?.link?.target}>{block?.link?.title}</Link>
              </div>}
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default WebinarsBlock;
