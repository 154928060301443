import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "./WebinarAbout.module.css";

const WebinarAbout = ({ block }) => {

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarAboutBlock-bg-color"] = block.backgroundColor;
  }

  return (
    <>
      {!!block.show &&
        <section className={`${s.webinarAboutBlock}`} style={styles}>
          <div className="container">
            <div className={`row`}>
              <div className="col-12">
                {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
              </div>

              <div className="col-lg-6 col-xxl-5">
                {!!block.leftTitle && <h3>{parse(block.leftTitle)}</h3>}
                {!!block.leftContent && <div>{parse(block.leftContent)}</div>}
              </div>

              <div className="col-lg-6 col-xxl-5 offset-xxl-1">
                {!!block.rightTitle && <h3>{parse(block.rightTitle)}</h3>}
                {!!block.rightContent && <div>{parse(block.rightContent)}</div>}
              </div>

              <div className="col-12">
                <div className={`d-flex justify-content-center`}>
                  {!!block?.useCustomLink
                    ? <>
                      {!!block?.buttonLink?.url &&
                        <Link
                          to={block?.buttonLink?.url} target={block?.buttonLink?.target}
                          className={`${s.button}`}
                        >{parse(block?.buttonLink?.title)}</Link>
                      }
                    </>
                    : <>
                      {!!block.buttonText &&
                        <Link
                          to={'#register'}
                          className={`${s.button}`}
                        >{parse(block.buttonText)}</Link>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default WebinarAbout;
