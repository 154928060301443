import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./WebinarHero.module.css";

const WebinarHero = ({ block }) => {
  const bp = useBreakpoint();
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };
  const imageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageMobile?.altText || ``,
  };

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarHeroBlock-bg-color"] = block.backgroundColor;
  }

  const runningString = `
    ${!!block.title ? `<span>${parse(block.title)}</span>` : ''}
    ${!!block.webinarDate && `<span>Date: ${parse(block.webinarDate)}</span>`}
    ${!!block.time && `<span>Time: ${parse(block.time)}</span>`}
  `;

  return (
    <>
      <section className={`${s.webinarHeroBlock} d-flex`} style={styles}>
        <div className="container">
          <div className={`${s.row} row align-items-center`}>
            <div className="col-12 col-xl-6">
              {!!block.title && <h1 className={`${s.title}`}>{parse(block.title)}</h1>}
              {!!block.subtitle &&
                <div className={`${s.content}`}>{parse(block.subtitle)}</div>
              }
              {(bp.md) &&
                <div className={`d-flex justify-content-center justify-content-xl-start`}>
                  {!!block?.useCustomLink
                    ? <>
                      {!!block?.buttonLink?.url &&
                        <Link
                          to={block?.buttonLink?.url} target={block?.buttonLink?.target}
                          className={`${s.topBlockButton} d-none d-md-inline-block`}
                        >{parse(block?.buttonLink?.title)}</Link>
                      }
                    </>
                    : <>
                      {!!block.buttonText &&
                        <Link
                          to={'#register'}
                          className={`${s.topBlockButton} d-none d-md-inline-block`}
                        >{parse(block.buttonText)}</Link>
                      }
                    </>
                  }
                </div>
              }
            </div>

            <div className='col-xl-6'>
              {(bp.md) ?
                <>
                  {(image.data) &&
                    <div className={`${s.imgWrapper} mx-4 px-3`}>
                      <div className={`${s.bg}`}></div>
                      <GatsbyImage
                        loading="eager"
                        placeholder="dominantColor"
                        image={image.data}
                        alt={image.alt}
                        className={`mw-100`} />
                    </div>
                  }
                </> :
                <>
                  {imageMobile.data &&
                    <div className={`d-flex justify-content-center`}>
                      <GatsbyImage
                        loading="eager"
                        placeholder="dominantColor"
                        image={imageMobile.data}
                        alt={imageMobile.alt}
                        className={`mw-100`} />
                    </div>
                  }
                </>
              }
              {(!bp.md && !!block.webinarDate || !!block.time) && <>
                <div className={`${s.dateTime}`}>
                  {!!block.webinarDate && <span>Date: {block.webinarDate}</span>}
                  {!!block.time && <span>Time: {block.time}</span>}
                </div>
              </>}
              {(!bp.md) &&
                <>
                  {!!block?.useCustomLink
                    ? <>
                      {!!block?.buttonLink?.url &&
                        <div className='d-flex d-md-none justify-content-center'>
                          <Link
                            to={block?.buttonLink?.url} target={block?.buttonLink?.target}
                            className={`${s.topBlockButton}`}
                          >{parse(block?.buttonLink?.title)}</Link>
                        </div>
                      }
                    </>
                    : <>
                      {!!block.buttonText &&
                        <div className='d-flex d-md-none justify-content-center'>
                          <Link
                            to={'#register'}
                            className={`${s.topBlockButton}`}
                          >{parse(block.buttonText)}</Link>
                        </div>
                      }
                    </>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </section>
      {!!runningString &&
        <section className={`${s.runningText}`} style={{ '--running-string-length': ((block.title.length + block.webinarDate.length + block.time.length) / 3) + 's' }}>
          <div className={`${s.movingText}`}>
            {parse(runningString)}
            {parse(runningString)}
            {parse(runningString)}
          </div>
        </section>
      }
    </>
  );
};

export default WebinarHero;
