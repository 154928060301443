import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React, { useState } from "react";
import * as s from "./faqquestion.module.css";

const FaqQuestion = ({ number, question, children }) => {
  const bp = useBreakpoint();
  const [opened, setOpened] = useState(false);

  const toggleOpened = e => {
    e.preventDefault();
    setOpened(!opened);
  };

  return (
    <>
      <div
        className={`${opened ? s.collapsibleOpen : ""} ${s.accordionCollapsible} d-flex`}
      >
        {bp.xl &&
          <div className={`${s.number} d-none d-xl-block`}>{number < 10 ? '0' + number : number}</div>
        }
        <div className="flex-grow-1">
          <button className={s.collapsibleHeader} onClick={toggleOpened}>
            <p>
              {!bp.xl &&
                <span className="d-xl-none">{number < 10 ? '0' + number : number}. </span>
              }
              {!!question && parse(question)}
            </p>
          </button>
          {!!children && <div className={s.collapsibleContent}>{parse(children)}</div>}
        </div>
      </div>
    </>
  );
};

export default FaqQuestion;
