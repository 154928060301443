import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useRef } from "react";
import { useInViewport } from 'react-in-viewport';
import * as s from "./WebinarSpeaker.module.css";

const WebinarSpeaker = ({ block }) => {
  const bp = useBreakpoint();

  const webinarSpeakerRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    webinarSpeakerRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };
  const imageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageMobile?.altText || ``,
  };

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarSpeakerBlock-bg-color"] = block.backgroundColor;
  }

  return (
    <>
      {!!block.show &&
        <section className={`${s.webinarSpeakerBlock}`} style={styles} ref={webinarSpeakerRef}>
          <div className="container">
            <div className={`row align-items-center`}>
              <div className="col-12 order-1">
                {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
              </div>
              {/* {enterCount > 0 && */}
                <>
                  <div className="col-lg-7 order-3 order-lg-2">
                    {!!block.content && <div className={`${s.content}`}>{parse(block.content)}</div>}
                  </div>

                  <div className="col-lg-5 order-2 order-lg-3">
                    {(bp.lg) ?
                      <>
                        {(image.data) &&
                          <GatsbyImage
                            loading="eager"
                            placeholder="dominantColor"
                            image={image.data}
                            alt={image.alt}
                            className={`mw-100`} />
                        }
                      </> :
                      <>
                        {imageMobile.data &&
                          <div className="d-flex justify-content-center">
                            <GatsbyImage
                              loading="eager"
                              placeholder="dominantColor"
                              image={imageMobile.data}
                              alt={imageMobile.alt}
                              className={`mw-100 mx-auto`} />
                          </div>}
                      </>
                    }
                  </div>
                  <div className="col-12 order-4">
                    <div className={`d-flex justify-content-center justify-content-lg-start`}>
                      {!!block?.useCustomLink
                        ? <>
                          {!!block?.buttonLink?.url &&
                            <Link
                              to={block?.buttonLink?.url} target={block?.buttonLink?.target}
                              className={`${s.button}`}
                            >{parse(block?.buttonLink?.title)}</Link>
                          }
                        </>
                        : <>
                          {!!block.buttonText &&
                            <Link
                              to={'#register'}
                              className={`${s.button}`}
                            >{parse(block.buttonText)}</Link>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
              {/* } */}
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default WebinarSpeaker;
