import { Link } from "gatsby";
import parse from "html-react-parser";
import { useInViewport } from 'react-in-viewport';
import React, { useRef } from "react";
import * as s from "./WebinarWhyJoin.module.css";

const WebinarWhyJoin = ({ block }) => {

  const webinarWhyJoinRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    webinarWhyJoinRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const styles = {};

  if (!!block.backgroundColor) {
    styles["--webinarWhyJoinBlock-bg-color"] = block.backgroundColor;
  }

  return (
    <>
      {!!block.show &&
        <section className={`${s.webinarWhyJoinBlock}`} style={styles} ref={webinarWhyJoinRef}>
          <div className="container">
            <div className={`row justify-content-xxl-between`}>
              <div className="col-12">
                {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
              </div>
              {/* {enterCount > 0 && */}
              <>
                <div className="col-12">
                  {!!block.content && <div className={`${s.rightContent}`}>{parse(block.content)}</div>}
                </div>
              </>
              {/* } */}
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default WebinarWhyJoin;
